/* eslint-disable react/jsx-no-comment-textnodes */
import { CircularProgress, Grid } from '@mui/material';
// import { makeStyles } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GenericForm from '../GenericForm';
import { colors } from '../../../styles/colors';
import { formMasks } from '../../../helpers/form/masks';
import Button from '../../Button';
import {
  FormControl as FC,
  InputCheckbox,
  InputText,
  ProspectsFormControl,
} from '../../Interface';
// import PrivacyPolicyModal from '../../PrivacyPolicyModal';
import { ProspectFormSchema } from './schema';
import { FormPropTypes, FormDefaultProps } from '../types';
import ContentCard from '../../ContentCard';
import { Heading2, Paragraph } from '../../Interface/Text';
import {
  CustomErrorMessage,
  CustomField,
  FieldWrapper,
  Label,
} from '../../Interface/Form';
import {
  PROFILE_TYPES,
  PROFILE_TYPES_LABEL,
} from '../../../helpers/profileTypes';
import { TAG_TYPES, TAG_TYPES_LABEL } from '../../../helpers/tagTypes';
import GradientButton from '../../GradientButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { isEmpty } from '../../../utils';
import ProducersService from '../../../services/administrator/producers.service';
import theme from '../../../theme';

export const BaseWaitlistForm = ({
  onSubmit,
  initialValues,
  handleClose,
  providers,
  companies,
  values,
  loadingg,
  setLoadingg,
  setReload,
  reload,
}) => {
  const defaultValues = {
    name: '',
    pis_cofins: 0,
    credit_value: 0,
    icms_te: 0,
    icms_tusd: 0,
    charges_te: 0,
    provider: '',
    volume: 0,
    volume_forecast: 0,
    te_liq: 0,
    tusd_liq: 0,
    provider_id: 0,
    price_floor: 0,
    cooperative_contract_text: 'Cooperativa Nacional Flora Energia, com sede na Avenida Eng. Luiz Carlos Berrini, 1178 - Cidade Monções, cidade de São Paulo, Estado de São Paulo, inscrita no CNPJ/MF 38.232.616/0001-60',
    ...initialValues,
  }
  const [providerId, setProviderId] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(defaultValues);
  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if (name === 'provider') {
        setProviderId(event?.target?.value?.split('--')?.[1]);
        setFormData((state) => ({
          ...state,
          provider_id: event?.target?.value?.split('--')[1],
          provider: event?.target?.value?.split('--')[0],
        }));
      } else if (name === 'company') {
        setFormData((state) => ({
          ...state,
          company_id: event?.target?.value,
        }));
      } else {
        setFormData((state) => ({
          ...state,
          [name]: value,
        }));
      }
    } catch (error) {
      console.log('file: index.js || line 97 || error', error);
    }
  };

  const handleDelete = async () => {
    const { id } = values;
    setLoadingg(true);
    try {
      await ProducersService.deleteOne({ producerId: id });
      setLoadingg(false);
    } catch (error) {
      setLoadingg(false);
      console.log(error);
    } finally {
      handleClose();
      setReload(!reload);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(formData, { isSubmitting });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (values) {
      setFormData(values);
    } else {
      setFormData(defaultValues);
    }
  }, [values]);
  try {
    return (
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <UpperDiv
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Heading2 style={{ fontSize: '40px' }}>
            {values ? 'Alterar Gerador' : 'Cadastrar Gerador'}
          </Heading2>
          <ClearIcon
            style={{ color: theme.palette.primary.main }}
            onClick={handleClose}
            id='clear'
          />
        </UpperDiv>
        <MainContainer>
          <ColumnContainer>
            <TextField
              variant='outlined'
              label='Nome'
              placeholder='Nome'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='name'
              defaultValue={values ? values.name : formData.name}
            />
            <TextField
              variant='outlined'
              label='Desconto Padrão (%):'
              placeholder='Desconto Padrão (%):'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='discount'
              type='number'
              defaultValue={values ? values.discount : formData.discount}
            />

            <TextField
              variant='outlined'
              label='Desconto Promocode (%):'
              placeholder='Desconto Promocode (%):'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='promo_discount'
              type='number'
              defaultValue={
                values ? values.promo_discount : formData.promo_discount
              }
            />
          </ColumnContainer>
          <ColumnContainer>
            <TextField
              variant='outlined'
              label='ICMS TE (%):'
              placeholder='ICMS TE (%):'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='icms_te'
              type='number'
              defaultValue={values ? values.icms_te : formData.icms_te}
            />
            <TextField
              variant='outlined'
              label='ICMS TUSD (%):'
              placeholder='ICMS TUSD (%):'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='icms_tusd'
              type='number'
              defaultValue={values ? values.icms_tusd : formData.icms_tusd}
            />
            <TextField
              variant='outlined'
              label='Encargos TE:'
              placeholder='Encargos TE:'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='charges_te'
              type='number'
              defaultValue={values ? values.charges_te : formData.charges_te}
            />
          </ColumnContainer>
          <ColumnContainer>
            <TextField
              variant='outlined'
              label='Pis + Cofins  (%):'
              placeholder='Pis + Cofins  (%):'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='pis_cofins'
              type='number'
              defaultValue={values?.pis_cofins || formData?.pis_cofins}
            />

            <TextField
              variant='outlined'
              label='Valor do Crédito:'
              placeholder='Valor do Crédito:'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='credit_value'
              type='number'
              defaultValue={values?.credit_value || formData.credit_value}
            />
            <TextField
              variant='outlined'
              label='Preco Piso:'
              placeholder='Preco Piso:'
              onChange={handleChange}
              name='price_floor'
              type='number'
              style={{ marginTop: '1rem' }}
              defaultValue={values ? values.price_floor : formData.price_floor}
            />
          </ColumnContainer>
          <ColumnContainer>
            <TextField
              variant='outlined'
              label='Te Líquida:'
              placeholder='Te Líquida:'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='te_liq'
              type='number'
              defaultValue={values ? values.te_liq : formData.te_liq}
            />
            <TextField
              variant='outlined'
              label='Tusd Líquida:'
              placeholder='Tusd Líquida:'
              onChange={handleChange}
              name='tusd_liq'
              style={{ marginTop: '1rem' }}
              type='number'
              defaultValue={values ? values.tusd_liq : formData.tusd_liq}
            />
            <TextField
              variant='outlined'
              label='Volume Rateio:'
              placeholder='Volume Rateio:'
              onChange={handleChange}
              name='volume'
              style={{ marginTop: '1rem' }}
              defaultValue={values ? values.volume : formData.volume}
            />
          </ColumnContainer>
          <ColumnContainer>
            <div style={{ width: '100%', maxWidth: '195px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '45%' }}>
                  <FormControl
                    variant='outlined'
                    style={{
                      marginTop: '1rem',
                      width: '100%',
                    }}
                  >
                    <InputLabel htmlFor='demo-simple-select-outlined'>
                      Distribuidora
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      defaultValue={
                        values
                          ? `${values?.provider || 'On hold'}--${
                              values.provider_id
                            }`
                          : `${formData.provider}--${formData.provider_id}`
                      }
                      style={{ width: '100%' }}
                      onChange={handleChange}
                      label='Distribuidora'
                      name='provider'
                    >
                      <MenuItem value='On hold--null'>On hold</MenuItem>
                      {providers &&
                        providers.map((provider) => {
                          return (
                            <MenuItem
                              key={provider.id}
                              value={`${provider.name}--${provider.id}`}
                            >
                              {provider.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>

                <div style={{ width: '45%' }}>
                  <FormControl
                    variant='outlined'
                    style={{
                      marginTop: '1rem',
                      width: '100%',
                    }}
                  >
                    <InputLabel htmlFor='demo-simple-select-outlined-companies'>
                      Empresa
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-outlined-label-companies'
                      id='demo-simple-select-outlined-companies'
                      defaultValue={
                        values
                          ? `${values.company_id}`
                          : `${formData.company}--${formData.company_id}`
                      }
                      onChange={handleChange}
                      label='Empresa'
                      name='company'
                    >
                      {companies &&
                        companies.map((company) => {
                          // console.log(company);
                          return (
                            <MenuItem
                              key={company.id}
                              value={company.id}
                            >
                              {company.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '45%' }}>
                  <TextField
                    variant='outlined'
                    label='Capacidade:'
                    placeholder='Capacidade:'
                    onChange={handleChange}
                    name='volume_forecast'
                    style={{ marginTop: '1rem' }}
                    type='number'
                    defaultValue={
                      values ? values.volume_forecast : formData.volume_forecast
                    }
                  />
                </div>
                <div style={{ width: '45%' }}>
                  <TextField
                    variant='outlined'
                    label='Dia de faturamento:'
                    placeholder='Dia de faturamento:'
                    onChange={handleChange}
                    name='billing_day'
                    style={{ marginTop: '1rem' }}
                    type='number'
                    defaultValue={
                      values ? values.billing_day : formData.billing_day
                    }
                  />
                </div>
              </div>
              <TextField
                variant='outlined'
                label='Qualificação Cooperativa:'
                placeholder='Qualificação Cooperativa:'
                onChange={handleChange}
                name='cooperative_contract_text'
                style={{ marginTop: '1rem' }}
                type='text'
                defaultValue={
                  values
                    ? values.cooperative_contract_text
                    : formData.cooperative_contract_text
                }
              />
            </div>
          </ColumnContainer>
        </MainContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <GradientButton
            handleClick={handleSubmit}
            disabled={isSubmitting}
            text=''
            fontSize='50'
            borderRadius='6'
            style={{
              width: 'max-content',
            }}
            type='submit'
            loading={isSubmitting}
          >
            {loadingg ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              <>{values ? 'Alterar' : 'Cadastrar'}</>
            )}
          </GradientButton>
          {values && (
            <GradientButton
              handleClick={handleDelete}
              disabled={isSubmitting}
              text=''
              fontSize='50'
              borderRadius='6'
              style={{
                width: 'max-content',
              }}
              type='submit'
              loading={isSubmitting}
              background='white'
              color={theme.palette.primary.main}
              border={`1px solid ${theme.palette.primary.main}`}
            >
              {loadingg ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                <>Deletar</>
              )}
            </GradientButton>
          )}
        </div>
      </form>
    );
  } catch (error) {
    console.log('file: index.js || line 415 || error', error);
  }
};

BaseWaitlistForm.propTypes = FormPropTypes;
BaseWaitlistForm.defaultProps = FormDefaultProps;

const ProspectForm = styled(BaseWaitlistForm)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;
export default ProspectForm;

const MainContainer = styled.section`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

const UpperDiv = styled.div`
  #clear {
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;
